"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import Pristine from "pristinejs";
import {onEnterViewPort} from "@elements/viewport-utils";
import {matches} from "@elements/dom-utils";
import {on, findAllIn, find, hasClass, closest, addClass, removeClass} from "@elements/dom-utils";

const defaultOptions = {
    classTo: 'form-group',
    errorClass: 'has-error',
    successClass: 'has-success',
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass: 'form-errors invalid-feedback'
};

const defaultSelectors = {
    base: '.js-form-validation'
};
console.log("create");
export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createFormValidation(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createFormValidation(form, options, selectors) {

    console.log("create");
    onEnterViewPort(form, function () {
        const pristine = new Pristine(form, options);
        
        console.log("HERE_"); 

        form.addEventListener('submit', function (e) {
            // check if the form is valid
            if (!pristine.validate()){
                console.log("not valid");
                e.preventDefault();
            }
        });
    });

}

export function isValidationForm (form) {
    return matches('.js-form-validation', form);
}

export function isValid(form) {
    if(matches('form', form)){
        const pristine = new Pristine(form, defaultOptions);
        return pristine.validate();
    }else{
        return 'Using parsley without form';
    }
}